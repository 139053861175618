import React from 'react'
import Layout from '../../components/layout'
import {
	Container,
	Section,
	Row,
	Column,
	ContactForm,
} from '../../components/layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	BetaLabel,
	Button,
	Image,
	Card,
	Collapsible,
	Link,
} from '../../components/ui'

import theme from '../../theme/theme'

const {
	intro,
	callToAction,
	donationAbout,
	securityMatters,
	leaveImpact,
	frequentlyQuestions,
	wantMore,
} = theme.sections

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: true,
		}

		this.handleScroll = this.handleScroll.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleScroll() {
		this.scrolledElement = document.getElementById('cardsContainer')

		if ((this.scrolledElement.offsetTop - (window.innerHeight / 2)) < window.scrollY) {
			this.setState({
				animateCards: true
			})
		}
	}

	render() {
		return (
			<Layout>
				{/* Intro section */}
				<Section id="intro" bg={intro.bg} paddingTop={'72px'} smallPaddingBottom={'25px'}>
					<Container>
						{/* Intro first section */}
						<Row marginBottom={'6em'}>
							<Image src={intro.content.firstPart.image} marginTop={'3em'} style={{width: '100%'}}/>
							<Paragraph style={{fontSize: 6, textAlign: 'right'}}>
										Organization brands and logos located on screenshots are reflective of nonprofits that have recieved donations on Flourish. Organization brands and logos are owned by the represented organization.
							</Paragraph>
							<Title fontSize={'50px'}>{intro.content.firstPart.title}</Title>
							<Paragraph fontSize={'21px'} marginBottom={'2em'} maxWidth={'420px'}>{intro.content.firstPart.paragraph}</Paragraph>
							<Paragraph fontSize={'16px'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.firstPart.labelBtn}</Paragraph>
							<Button href={`/m${intro.content.firstPart.btn.link}`} maxWidth={'160px'} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black}>{intro.content.firstPart.btn.text}</Button>
						</Row>
					</Container>
				</Section>
				{/* Intro second section */}
				<Section style={{marginTop: '-1px'}}>
					<Container>
						<Row marginBottom={'4em'}>
							<Column center>
								<Title fontSize={'33px'} textAlign={'center'}>{intro.content.secondPart.title}</Title>
								<Paragraph maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.paragraph}</Paragraph>
							</Column>
						</Row>
						<Row marginBottom={'4em'}>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.first.image} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.first.title}</Subtitle>
									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.first.paragraph}</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.second.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.second.title}</Subtitle>
									<Paragraph height={'78px'} maxWidth={'500px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.second.paragraph}</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.third.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>{intro.content.secondPart.columns.third.title}</Subtitle>
									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>{intro.content.secondPart.columns.third.paragraph}</Paragraph>
								</div>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Call to action section */}
				<Section bg={callToAction.bg} paddingTop={'5em'} paddingBottom={'5em'}>
					<Container>
						<Row>
							<Column>
								<div>
									<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{callToAction.content.title}</Title>
									<Paragraph color={theme.colors.white} maxWidth={'400px'} fontSize={'20px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'200'}>{callToAction.content.paragraph}</Paragraph>
									<Row marginBottom={'0'} style={{jusifyContent: 'center', alignItems: 'center'}}>
										<Column width={'100%'} style={{jusifyContent: 'center', alignItems: 'center'}}>
											<Link href={callToAction.content.appStore.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.appStore.image} marginBottom={'0'} height={'48px'} maxWidth={'144px'} smallWidth={'none'} style={{margin: 'auto'}}/>
											</Link>
											<Link href={callToAction.content.googlePlay.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'} style={{margin: 'auto'}}/>
											</Link>
										</Column>
									</Row>
								</div>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Donation about section */}
				<Section id="features" paddingTop={'3em'} paddingBottom={'3em'}>
					<Container maxWidth={'100%'}>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'2em'}>{donationAbout.content.title}</Title>
							</Column>
						</Row>
						<Row>
							{donationAbout.content.features && donationAbout.content.features.map((item, i) =>
								(<Column maxWidth={(100 / donationAbout.content.features.length) + '%'} key={i}>
									{item.betaLabel && <BetaLabel src={item.betaLabel} left={'20px'} right={'0'} top={'0'} marginLeft={'auto'} marginRight={'auto'} maxWidth={'70px'} />}
									<Image src={item.icon} marginBottom={'4em'} maxWidth={'92px'} maxHeight={'92px'} />
									<div>
										<Subtitle fontSize={'21px'} marginBottom={'0.5em'} textAlign={'center'}>{item.title}</Subtitle>
										<Paragraph height={'78px'} fontSize={'14px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0'} maxWidth={'233px'}>{item.paragraph}</Paragraph>
									</div>
								</Column>)
							)}
						</Row>
					</Container>
				</Section>
				{/* Security matters section */}
				<Section bg={securityMatters.bg} paddingTop={'5em'} paddingBottom={'5em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{securityMatters.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'706px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.5em'} fontWeight={'200'}>{securityMatters.content.paragraph}</Paragraph>
								<Button href={`/m${ securityMatters.content.btn.link}`} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginLeft={'auto'} marginRight={'auto'} smallMarginLeft={'auto'} smallMarginRight={'auto'} maxWidth={'200px'}>{securityMatters.content.btn.text}</Button>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Leave impact section */}
				<Section id="cardsContainer" bg={leaveImpact.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{leaveImpact.content.title}</Title>
								<Paragraph maxWidth={'370px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>{leaveImpact.content.paragraph}</Paragraph>
							</Column>
						</Row>
						<Row style={{marginLeft: 'auto', marginRight: 'auto'}}>
							<Column maxWidth={'50%'}>
								<Card animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'}>
											<Image smallWidth={'100%'} src={leaveImpact.features.first.icon} marginBottom={'0'} maxWidth={'92px'} />
										</Column>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{leaveImpact.features.first.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{leaveImpact.features.first.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
								<Card animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'}>
											<Image smallWidth={'100%'} src={leaveImpact.features.second.icon} marginBottom={'0'} maxWidth={'92px'} />
										</Column>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{leaveImpact.features.second.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{leaveImpact.features.second.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
							</Column>
							<Column maxWidth={'50%'}>
								<Card animatedRight className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'}>
											<Image smallWidth={'100%'} src={leaveImpact.features.third.icon} marginBottom={'0'} maxWidth={'92px'} />
										</Column>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{leaveImpact.features.third.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{leaveImpact.features.third.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
								<Card animatedRight className={this.state.animateCards ? 'animate' : false}>
									{leaveImpact.features.fourth.betaLabel && <BetaLabel src={leaveImpact.features.fourth.betaLabel} left={'auto'} right={'0'} top={'0'} marginLeft={'auto'} marginRight={'auto'} maxWidth={'70px'} />}
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'}>
											<Image smallWidth={'100%'} src={leaveImpact.features.fourth.icon} marginBottom={'0'} maxWidth={'92px'} />
										</Column>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{leaveImpact.features.fourth.title}</Subtitle>
											<Paragraph fontSize={'14px'}>{leaveImpact.features.fourth.paragraph}</Paragraph>
										</Column>
									</Row>
								</Card>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Frequently Questions section */}
				<Section paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{frequentlyQuestions.content.title}</Title>
								<Paragraph fontSize={'16px'} marginLeft={'auto'} marginRight={'auto'} maxWidth={'491px'} textAlign={'center'} dangerouslySetInnerHTML={{ __html: frequentlyQuestions.content.paragraph }} />
							</Column>
						</Row>
						<Row>
							<Column>
								<Collapsible list={frequentlyQuestions.questions} />
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Want to learn more section */}
				<Section bg={wantMore.bg} paddingTop={'5em'} paddingBottom={'3em'}>
					<Container maxWidth={'458px'}>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{wantMore.content.title}</Title>
							</Column>
						</Row>
						<Row center>
							<Column maxWidth={'458px'} style={{margin: 'auto'}}>
								<ContactForm />
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Footer */}
			</Layout>
		)
	}
}

export default IndexPage
